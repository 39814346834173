import React from 'react';
import {motion} from "framer-motion";
import './index.scss';
import condition_banner from '../../assets/images/condition_banner.png';

function ConditionMember(props: any) {

    return (
        <div className={"container-fluid condition-member-container g-0"}>
            <div className={"container-title text-center my-3"}>
                <h2>Điều kiện hội viên</h2>
            </div>
            <div className={"container condition-container"}>
                <div className={"row g-4"}>
                    <div className={"col-12 col-md-6 mx-auto card-column"}>
                        <motion.img initial={{opacity: 0, scale: .8}} whileInView={{opacity: 1, scale: 1}} transition={{duration: .8}} src={condition_banner} alt={"Điều kiện hội viên"} className={"img-fluid condition-image w-100"}/>
                    </div>
                </div>
                <div className={"text-center condition-content"}>
                    <h4>Đáp ứng những điều kiện sau để trở thành hội viên của SeAPower:</h4>
                    <h6>1. Là doanh nghiệp do phụ nữ làm chủ</h6>
                    <h6>2. Là khách hàng sử dụng ít nhất 01 sản phẩm/ dịch vụ tại SeABank</h6>
                    <div className={"mt-5"}>Tìm hiểu thêm về điều kiện gia nhập và tiêu chí xếp hạng tại đây</div>
                </div>
            </div>
        </div>
    );
}

export default ConditionMember;
