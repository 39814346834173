import React, {useState} from 'react';
import {motion} from "framer-motion";
import './index.scss';
import {Field, Form, Formik} from "formik";

import contact_banner from '../../assets/images/contact_banner.webp';
import * as Yup from "yup";
import {configs} from "../../configs";


function Contact(props: any) {
    // const [token, setToken] = useState<string>("");
    const [serverError, setServerError] = useState("");
    const [isSuccess, setSuccess] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const formId = "1FAIpQLSeFn-bvyY7erci2o7D7O9qshVpb5fKo_DrSsxeN1_uFhsF8fA";

    const onSubmit = async (values: any, {setSubmitting}: any) => {
        try {
            // reset state
            setLoading(true);
            setServerError("");
            setSuccess(false);

            // let formData: FormData = new FormData();
            // formData.append("entry.2005620554", values.name);
            // formData.append("entry.1542262508", values.job);
            // formData.append("entry.998926656", values.company);
            // formData.append("entry.1166974658", values.phone);
            // formData.append("entry.1045781291", values.email);


            let formData = {
                "entry.734697380": values.name,
                "entry.943098540": values.job,
                "entry.858668114": values.company,
                "entry.836479046": values.phone,
                "entry.704959998": values.email,
            };
            let data = new URLSearchParams(formData)

            let res = await fetch(configs.backend + `/forms/d/e/${formId}/formResponse`, {method: "POST", body: data, mode: 'no-cors', headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/x-www-form-urlencoded'}});
            if (res.status !== 200 && res.status !== 0) {
                console.log(res)
                setServerError("Đăng ký chưa thành công, vui lòng thử lại sau");
            } else {
                setSuccess(true);
            }
        } catch (e) {
            console.log(e);
            setServerError("Đăng ký chưa thành công, vui lòng thử lại sau");
        } finally {
            setLoading(false);
        }
    };


    const RegisterSchema = Yup.object().shape({
        name: Yup.string()
            .min(2, 'Họ và tên quá ngắn, cần ít nhất 2 ký tự')
            .max(50, 'Họ và tên quá dài, tối đa 50 ký tự')
            .required('Họ và tên không được để trống'),
        job: Yup.string()
            .min(2, 'Chức danh quá ngắn, cần ít nhất 2 ký tự')
            .max(50, 'Chức danh quá dài, tối đa 50 ký tự')
            .required('Chức danh không được để trống'),
        company: Yup.string()
            .min(2, 'Tên công ty quá ngắn, cần ít nhất 2 ký tự')
            .max(50, 'Tên công ty quá dài, tối đa 50 ký tự')
            .required('Tên công ty không được để trống'),
        phone: Yup.string()
            .min(8, 'Số điện thoại quá ngắn, cần ít nhất 10 ký tự')
            .max(11, 'Số điện thoại quá dài, tối đa 11 ký tự')
            .required('Số điện thoại không được để trống'),
        email: Yup.string().email('Email chưa chính xác').required('Email không được để trống'),
    });


    return (
        <div id={"contact"} className={"container-fluid contact-container g-0"}>
            <div className={"container"}>
                <div className={"row flex-md-row-reverse g-0"}>
                    <div className={"col-12 col-lg-6 contact-column"}>
                        <div className={"rounded-3"} style={{overflow: "clip"}}>
                            <motion.img initial={{opacity: 0, scale: .8}} whileInView={{opacity: 1, scale: 1}} transition={{duration:.8}} src={contact_banner} alt={"Đăng ký nhận tư vấn"} className={"img-fluid w-100"}/>
                        </div>
                    </div>
                    <div className={"col-12 col-lg-4 ms-auto contact-column d-flex flex-column justify-content-center"}>
                        <div className={"contact-title text-center mt-3"}>
                            <h2>Đăng ký nhận tư vấn</h2>
                        </div>
                        <Formik
                            validationSchema={RegisterSchema}
                            initialValues={{name: "", job: "", company: "", phone: "", email: ""}}
                            onSubmit={onSubmit}
                            isInitialValid={false}
                        >
                            {({errors, touched, isValid, dirty}) => (
                                <Form className={"contact-form needs-validation was-validated"}>
                                    <div className={"input-group"}>
                                        <Field name="name" type="text" className={"form-control " + (errors.name && touched.name ? "is-invalid" : "is-valid")} placeholder={"Họ và tên"}/>
                                        {errors.name && touched.name ? (<small className={"invalid-feedback"}>{errors.name}</small>) : null}
                                    </div>
                                    <div className={"input-group"}>
                                        <Field name="job" type="text" className={"form-control " + (errors.job && touched.job ? "is-invalid" : "is-valid")} placeholder={"Chức danh"}/>
                                        {errors.job && touched.job ? (<small className={"invalid-feedback"}>{errors.job}</small>) : null}
                                    </div>
                                    <div className={"input-group"}>
                                        <Field name="company" type="text" className={"form-control " + (errors.company && touched.company ? "is-invalid" : "is-valid")} placeholder={"Tên doanh nghiệp"}/>
                                        {errors.company && touched.company ? (<small className={"invalid-feedback"}>{errors.company}</small>) : null}
                                    </div>
                                    <div className={"input-group"}>
                                        <Field name="phone" type="tel" className={"form-control " + (errors.phone && touched.phone ? "is-invalid" : "is-valid")} placeholder={"Điện thoại"}/>
                                        {errors.phone && touched.phone ? (<small className={"invalid-feedback"}>{errors.phone}</small>) : null}
                                    </div>
                                    <div className={"input-group"}>
                                        <Field name="email" type="email" className={"form-control " + (errors.email && touched.email ? "is-invalid" : "is-valid")} placeholder={"Email"}/>
                                        {errors.email && touched.email ? (<small className={"invalid-feedback"}>{errors.email}</small>) : null}
                                    </div>
                                    <div className={"error-container"}>
                                        {(serverError) ? <small className={"error-feedback"}>{serverError}</small> : <></>}
                                    </div>
                                    <div className={"notify-container"}>
                                        {(isSuccess) ? <small className={"success-feedback"}>Đăng ký thành công</small> : <></>}
                                    </div>

                                    <button type="submit" disabled={isLoading} className={"btn btn-contact mx-auto mt-3" + (isLoading ? ' loading' : '')}>
                                        <span>
                                            <span className={"text-gradient"}><span>Đăng ký trở</span><span>thành hội viên</span></span>
                                        </span>
                                        <div className={"spin-container"}>
                                            <i className="fa-solid fa-spinner fa-spin"></i>
                                        </div>
                                    </button>

                                </Form>

                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
