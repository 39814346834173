import React, {useEffect} from 'react';
import {Helmet, HelmetProvider} from "react-helmet-async";
import {useDispatch} from 'react-redux';
import {animateScroll as scroll} from "react-scroll";
import {motion} from "framer-motion";

import {addSlide} from '../../stores/SliderSlice';

import './App.scss';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Banner from "../../components/Banner";
import Slider from "../../components/Slider";
import EventInfo from "../../components/EventInfo";
// import OurValue from "../../components/OurValue";
import Benefit from "../../components/Benefit";
import ConditionMember from "../../components/ConditionMember";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer";
import NavMobile from "../../components/NavMobile";

import slide_1 from '../../assets/images/slides/slide_1.webp';
import slide_2 from '../../assets/images/slides/slide_2.webp';
import slide_3 from '../../assets/images/slides/slide_3.webp';
import slide_4 from '../../assets/images/slides/slide_4.webp';

function App() {
    const dispatch = useDispatch();
    let slides: SlideContent[] = [
        {
            id: 1,
            title: 'SHE OWNS BUSINESS',
            description: 'Roadshow chào mừng ngày Quốc tế phụ nữ 8/3',
            image: slide_1,
            viewmore: {
                title: 'Tìm hiểu thêm',
                url: '#'
            }
        },
        {
            id: 2,
            title: 'SWEET AURA - LAN TỎA NGỌT NGÀO',
            description: 'Sự kiện tôn vinh phụ nữ Việt',
            image: slide_2,
            viewmore: {
                title: 'Tìm hiểu thêm',
                url: '#'
            }
        },
        {
            id: 3,
            title: 'RING THE BELL',
            description: 'Sự kiện rung chuông vì bình đằng giới',
            image: slide_3,
            viewmore: {
                title: 'Tìm hiểu thêm',
                url: '#'
            }
        },
        {
            id: 4,
            title: 'ĐÀO TẠO GIA TĂNG NĂNG LỰC DOANH NGHIỆP NỮ',
            description: 'Sự kiện đào tạo',
            image: slide_4,
            viewmore: {
                title: 'Tìm hiểu thêm',
                url: '#'
            }
        }
    ];

    // Add slides to store
    dispatch(addSlide(slides));

    const scrollToTop = () => {
        scroll.scrollToTop();
    };

    useEffect(() => {
        document.title = 'SeAPower - Đặc quyền nữ chủ | SeABank - Ngân hàng số';

        if (!window.YT) {
            let player: any;
            let onYouTubeIframeAPIReady = () => {
                player = new window.YT.Player('player1', {
                    videoId: 'd6u7o4hC3tk',
                    height: '1080',
                    width: '1920',
                    playerVars: {
                        autoplay: 1,
                        rel: 0,
                        showinfo: 0,
                        modestbranding: 1,
                        playsinline: 1,
                        controls: 0,
                        color: 'white',
                        loop: 1,
                        playlist: 'd6u7o4hC3tk'
                    },
                    events: {
                        onReady: onPlayerReady,
                        'onStateChange': onPlayerStateChange
                    }
                });
            }

            let onPlayerStateChange = (event: any) => {
                if (event.data === window.YT.PlayerState.ENDED) {
                    player.playVideo();
                }
            }

            let onPlayerReady = (event: any) => {
                player.playVideo();
                player.setLoop(true);
                player.mute();
            }

            window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
            window.onPlayerReady = onPlayerReady;
        }
    })

    return (
        <HelmetProvider>
            <Helmet>
                <script src={"https://www.youtube.com/iframe_api"}></script>
            </Helmet>
            <motion.div whileInView={{opacity: 1}} initial={{opacity: 0}} transition={{duration: .5}}>
                <Banner/>
            </motion.div>
            <motion.div whileInView={{opacity: 1}} initial={{opacity: 0}} transition={{duration: .5}}>
                <Slider/>
            </motion.div>
            <motion.div whileInView={{opacity: 1}} initial={{opacity: 0}} transition={{duration: .5}}>
                <EventInfo/>
            </motion.div>
            {/*<OurValue/>*/}
            <motion.div whileInView={{opacity: 1}} initial={{opacity: 0}} transition={{duration: .5}}>
                <Benefit/>
            </motion.div>
            <motion.div whileInView={{opacity: 1}} initial={{opacity: 0}} transition={{duration: .5}}>
                <ConditionMember/>
            </motion.div>
            <motion.div whileInView={{opacity: 1}} initial={{opacity: 0}} transition={{duration: .5}}>
                <Contact/>
            </motion.div>
            <motion.div whileInView={{opacity: 1}} initial={{opacity: 0}} transition={{duration: .5}}>
                <Footer/>
            </motion.div>
            <NavMobile className={"d-block d-md-none"}/>
            <div className={"scroll-top"} onClick={scrollToTop}>
                <i className={"fas fa-chevron-up"}></i>
            </div>
        </HelmetProvider>
    );
}

export default App;
